<template>
  <!-- 巡检标签 巡检点-新版本2020-07 -->
  <div class="setting_main_bg">
    <div class="lable_cont">
      <div class="lable_box">
        <div class="btn_group" v-if="roleBtnArray[0].power">
          <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
          <div class="settingExport" @click="handleConfig"><span>周期配置</span></div>
        </div>
        <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
          <el-input v-model="inpointname"  placeholder="请输入巡检点名称/标签编号" class="contInput" style="width: 250px;"></el-input>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>
        <div class="lable_table" style="position: relative;">
          <el-table
            stripe
            :data="labelList"
            style="width: 100%">
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="巡检名称"
              prop="inpointname">
            </el-table-column>
            <el-table-column
              label="打卡类型"
              prop="inpointtype">
              <template slot-scope="scope">
                <span v-if="scope.row.inpointtype == 0">全部</span>
                <span v-if="scope.row.inpointtype == 1">扫码</span>
                <span v-if="scope.row.inpointtype == 2">NFC</span>
              </template>
            </el-table-column>
            <el-table-column
              label="标签编号"
              prop="inpointcode">
            </el-table-column>
            <el-table-column
              label="巡检周期"
              prop="cycle">
            </el-table-column>
            <el-table-column
              label="启动时间"
              prop="inpointime">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- [新增]-[编辑]-弹框 -->
    <el-dialog title="巡检点" :visible.sync="addFromShow" width="900px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="labelForm" :rules="formRules" ref="labelFrom">
        <div class="tsExplain">
          <p>周期说明:</p>
          <p>1. 选择每日,则为启动时间的第二天生成该点位的任务列表</p>
          <p>2. 选择每周,则为启动时间后的第一个自然星期一生成该点位的任务列表</p>
          <p>3. 选择每月,则为启动时间下一个自然月一号生成该点位的任务列表</p>
        </div>
        <div class="flexCB">
          <el-form-item label="巡检点名称" class="foItem" prop="inpointname">
            <el-input v-model="labelForm.inpointname"  placeholder="请输入巡检点名称"></el-input>
          </el-form-item>
          <el-form-item label="打卡类型" class="foItem" prop="inpointtype">
            <el-select v-model="labelForm.inpointtype" placeholder="请选择打卡类型" class="adSe" style="width: 100%">
              <el-option v-for="item in pointType" :key="item.index"
                         :label="item.name"
                         :value="item.value">
                {{ item.name }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="标签编号" class="foItem" prop="inpointcode">
            <el-input v-model="labelForm.inpointcode"  placeholder="请输入标签编号" :disabled="addOrEdit"></el-input>
          </el-form-item>
          <el-form-item label="巡检周期" class="foItem" prop="inpointcycle">
            <el-select v-model="labelForm.inpointcycle" placeholder="请选择巡检周期" class="adSe" style="width: 100%">
              <el-option v-for="item in cycleType" :key="item.index"
                         :label="item.datavalue"
                         :value="item.dictid">
                {{ item.datavalue }}</el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexCB">
          <el-form-item label="标签地址" class="foItem" prop="inpointaddr">
            <el-input v-model="labelForm.inpointaddr"  placeholder="请输入标签地址"></el-input>
          </el-form-item>
          <el-form-item label="启动时间" class="foItem" prop="inpointime">
            <el-date-picker
              class="adSe"
              style="width: 390px;"
              v-model="labelForm.inpointime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择时间">
            </el-date-picker>
          </el-form-item>
        </div>
        <div style="width: 100%;position: relative">
          <div class="tsAdd" @click="markAdd">新增</div>
          <el-form-item  label="巡检要求" class="teItem" prop="inpointmark">
            <div v-for="(items, index) in labelForm.inpointmark" :key="index" style="margin-top: 16px;position: relative;">
              <el-input v-model="items.name"  placeholder="请输入巡检要求"></el-input>
              <i class="el-icon-circle-close tsDel"v-if="labelForm.inpointmark.length > 0" @click="markDel(index)"></i>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="labelConfirm">确定</div>
      </div>
    </el-dialog>

    <el-dialog title="周期配置" :visible.sync="configShow" width="900px" center class="gyDialog" :close-on-click-modal="false" :before-close="configCancel">
      <div class="flexCS">
        <p style="color: red;padding-top: 8px;">*</p>
        <p style="margin: 0 10px 0px 5px;">巡检周期</p>
        <el-select v-model="patrolZh" placeholder="请选择巡检周期" class="adSe">
          <el-option v-for="item in cycleType" :key="item.index"
                     :label="item.datavalue"
                     :value="item.dictid">
            {{ item.datavalue }}</el-option>
        </el-select>
      </div>
      <div class="conTable">
        <el-table
          stripe
          ref="configTable"
          :data="configList"
          style="width: 100%;"
          height="500"
          :row-key="getRowKeys"
          @row-click="handleRowClick"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            prop="inpointid"
            :reserve-selection="true">
          </el-table-column>
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="巡检点名称"
            prop="inpointname">
          </el-table-column>
          <el-table-column
            label="标签编号"
            prop="inpointcode">
          </el-table-column>
          <el-table-column
            label="巡检周期"
            prop="cycle">
          </el-table-column>
        </el-table>
      </div>
      <div class="flexCE">
        <div class="settingCancel" @click="configCancel">取消</div>
        <div class="settingConfirm" @click="configConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { onsiteLabelAdd, onsiteLabelQuery, onsiteLabelEdit, onsiteLabelDel, configOnsiteList, configEdit } from '@/http/api/onsite'
import { nounSearch } from '@/http/api/settingSystem'
export default {
  name: 'onsiteLabel',
  props: ['roleBtn'],
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      companyid: '',
      page: 1,
      size: 10,
      total: 0,
      inpointname: '',
      addFromShow: false,
      labelList: [],
      labelForm: {
        inpointname: '', // 巡检点名称
        inpointtype: '', // 打卡类型
        inpointcode: '', // 标签编号
        inpointcycle: '', // 巡检周期
        inpointaddr: '', // 标签地址
        inpointime: '', // 启动时间
        inpointmark: [] // 巡检要求
      },
      formRules: {
        inpointname: [
          { required: true, trigger: 'blur', message: '请输入巡检点名称' },
          { min: 1, max: 32, message: '长度在 1 到 20 个字符', trigger: 'change' }
        ],
        inpointtype: [{ required: true, trigger: 'blur', message: '请选择打卡类型!' }],
        inpointcode: [
          { required: true, trigger: 'blur', message: '请输入标签编号' },
          { min: 1, max: 32, message: '长度在 1 到 20 个字符', trigger: 'change' }
        ],
        // inpointcycle: [{ required: true, trigger: 'blur', message: '请选择巡检周期' }],
        inpointaddr: [
          { required: true, trigger: 'blur', message: '请输入标签地址' },
          { min: 1, max: 32, message: '长度在 1 到 50 个字符', trigger: 'change' }
        ],
        // inpointime: [{ required: true, trigger: 'blur', message: '请选择启动时间' }],
        inpointmark: [{ required: true, trigger: 'blur', message: '请添加巡检要求' }]
      },
      pointType: [{ name: '全部', value: 0 },{ name: '扫码', value: 1 },{ name: 'NFC', value: 2 }],
      cycleType: [],
      addOrEdit: false,
      configShow: false,
      patrolZh: '', // 配置-周期
      configList: [], // 配置-table列表
      conListId: [] // 配置-选择列表
    }
  },
  beforeMount () {
    if (this.roleBtn.length !== 0) {
      this.roleBtnArray = this.roleBtn
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        companyid: this.companyid,
        inpointname: this.inpointname
      }
      onsiteLabelQuery(params).then(res => {
        if (res.status === '1') {
          this.labelList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    /* 翻页 */
    PageChange (page) {
      this.page = page
      this.init()
    },
    /* 搜索 */
    handleSearch () {
      this.page = 1
      this.init()
    },
    /* 重置 */
    handleReset () {
      this.page = 1
      this.inpointname = ''
      this.init()
    },
    /* 巡检周期 */
    getPointCycle () {
      nounSearch({ config: 'inspecycle' }).then(res => {
        if (res.status === '1') {
          this.cycleType = res.data
        }
      })
    },
    /* 巡检内容-新增 */
    markAdd () {
      this.labelForm.inpointmark.push({name: ''})
    },
    /* 巡检内容-删除 */
    markDel (index) {
      this.labelForm.inpointmark.splice(index, 1)
    },
    /* 新增-展示 */
    handleAdd () {
      this.addFromShow = true
      this.addOrEdit = false
      this.getPointCycle() // 巡检周期获取
      this.$nextTick(() => {
        this.$refs.labelFrom.resetFields()
      })
    },
    /* 新增-编辑-关闭 */
    addFromCancel () {
      this.addFromShow = false
      this.$refs.labelFrom.resetFields()
    },
    /* 新增-编辑-表单提交 */
    labelConfirm () {
      this.$refs.labelFrom.validate((valid) => {
        if (!valid) return
        if (this.addOrEdit === false) {
          const params = {
            companyid: this.companyid,
            inpointtype: this.labelForm.inpointtype,
            inpointcode: this.labelForm.inpointcode,
            inpointname: this.labelForm.inpointname,
            inpointcycle: this.labelForm.inpointcycle,
            inpointaddr: this.labelForm.inpointaddr,
            inpointime: this.labelForm.inpointime,
            inpointmark: this.labelForm.inpointmark
          }
          if (this.labelForm.inpointmark.length > 0) { // 新增提交
            if (this.checkEmpty(this.labelForm.inpointmark)) {
              onsiteLabelAdd(params).then(res => {
                if (res.status === '1') {
                  this.$message.success(res.message)
                  this.addFromCancel()
                  this.init()
                } else {
                  this.$message.error(res.message)
                }
              })
            } else {
              this.$message.warning('请输入巡检要求')
            }
          }
        } else if (this.addOrEdit === true) { // 编辑提交
          const params = {
            companyid: this.labelForm.companyid,
            inpointtype: this.labelForm.inpointtype,
            inpointid: this.labelForm.inpointid,
            inpointname: this.labelForm.inpointname,
            inpointcycle: this.labelForm.inpointcycle,
            inpointaddr: this.labelForm.inpointaddr,
            inpointime: this.labelForm.inpointime,
            inpointmark: this.labelForm.inpointmark
          }
          if (this.labelForm.inpointmark.length > 0) {
            if (this.checkEmpty(this.labelForm.inpointmark)) {
              onsiteLabelEdit(params).then(res => {
                if (res.status === '1') {
                  this.$message.success(res.message)
                  this.addFromCancel()
                  this.init()
                } else {
                  this.$message.error(res.message)
                }
              })
            } else {
              this.$message.warning('请输入巡检要求')
            }
          }
        }
      })
    },
    checkEmpty (arr) {
      let res = false
      arr.forEach(item => {
        if (item.name === '') { res = false }
        else { res = true}
      })
      return res
    },
    /* 编辑-展示 */
    handleEdit (row) {
      this.addFromShow = true
      this.addOrEdit = true
      this.getPointCycle() // 巡检周期获取
      this.labelForm = {...row}
    },
    /* 删除-操作 */
    handleDelete (row) {
      this.$confirm('此操作将永久删除该巡检点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        onsiteLabelDel({ inpointid: row.inpointid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 周期配置-展示 */
    handleConfig () {
      this.getPointCycle() // 巡检周期获取
      this.getConfigList() // 配置列表
      this.configShow = true
    },
    /* 配置列表 */
    getConfigList () {
      configOnsiteList().then(res => {
        if (res.status === '1') {
          this.configList = res.data
        }
      })
    },
    /* 周期配置-取消-关闭 */
    configCancel () {
      this.configShow = false
      this.$refs.configTable.clearSelection()
      this.patrolZh = ''
    },
    getRowKeys (row) {
      return row.inpointid
    },
    handleRowClick (row, column, event) {
      this.$refs.configTable.toggleRowSelection(row)
    },
    handleSelectionChange (val) {
      if (val.length === 0) {
        this.conListId = []
      } else {
        this.conListId = val
      }
    },
    /* 周期配置-确认-提交 */
    configConfirm () {
      if (this.patrolZh !== '') {
        if (this.conListId.length !== 0) {
          const arr = []
          for (let i = 0; i < this.conListId.length; i++) {
            arr.push(this.conListId[i].inpointid)
          }
          configEdit({ inpointcycle: this.patrolZh, inpointid: arr }).then(res => {
            if (res.status === '1') {
              this.$message.success(res.message)
              this.init()
              this.configCancel()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          this.$message.warning('请选择巡检点')
        }
      } else {
        this.$message.warning('请选择巡检周期')
      }
    }
  }
}
</script>

<style scoped>
.lable_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.lable_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.lable_table {
  margin-top: 20px;
}
.typeBox {
  width: 100%;
  margin-top: 24px;
}
.tsExplain{
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: #F6F9FF;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}
.tsAdd{
  position: absolute;
  left: 80px;
  top: 0px;
  width: 50px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 12px;
  background: #196DF7;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}
.tsDel{
  margin-left: 10px;
  font-size: 20px;
  color:red;
  cursor: pointer;
}
.teItem{
  width: 100%;
  position: relative;
}
.teItem >>> .el-form-item__label{
  margin-bottom: 10px;
}
.teItem >>> .el-input{
  width: 95%;
}
.conTable{
  width: 100%;
  margin: 10px 0 20px;
  /*max-height: 500px;*/
  /*overflow-y: scroll;*/
  /*scrollbar-width: none;!* //火狐兼容 *!*/
}
.conTable >>> .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  /*width: 2px; !* 谷歌兼容 *!*/
}
</style>
